.react-datepicker__input-container input {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.375rem;
  color: #102536;
  padding-left: 1rem;
}

#canvas {
  width: 80%;
  height: auto;
  margin: 0 auto 10px auto;
}

@media screen and (min-width: 62em) {
  #canvas {
    width: 600px;
    height: 600px;
    margin: 0;
  }
}
