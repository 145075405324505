.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

.cropper-move {
  cursor: move;
}

.cropper-face {
  background-color: #fff;
  left: 0;
  right: 0;
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}
